<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="deleteCat" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-trash fa-2x"></i>
                </span>
            </div>
            
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Alerta!</strong></span><br>
            </div>

            <div v-if="deleteSuccess" class="d-flex justify-content-center text-center mt-2">
                <p class="subttitle-modal">¿Esta seguro de eliminar este dato?</p>
            </div>
            
            <div v-if="errorDeleteMedicamento" class="d-flex justify-content-center text-center mt-2">
                <p class="subttitle-modal">Medicamento en uso, imposible eliminar</p>
            </div>

            <div v-if="errorDeleteOperacion" class="d-flex justify-content-center text-center mt-2">
                <p class="subttitle-modal">Operación en uso, imposible eliminar</p>
            </div>

            <div v-if="errorDeleteDiagnostico" class="d-flex justify-content-center text-center mt-2">
                <p class="subttitle-modal">Diagnóstico en uso, imposible eliminar</p>
            </div>

            <div v-if="deleteSuccess" class="d-flex justify-content-center mt-2">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                >
                    <button @click="deleteData()" :disabled="busy" class="btn-modal-success border-0 px-3 pb-2 pt-1">Eliminar</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
            <div v-if="(errorDeleteMedicamento || errorDeleteDiagnostico || errorDeleteOperacion)" class="d-flex justify-content-center mt-2">
                <button @click="cerrarModal()" class="btn-modal-success border-0 px-3 pb-2 pt-1">Cancelar</button>
            </div>
        </b-modal>
    </div> 
</template>

<script>
import { url } from "../../../settings"

export default {
    name: 'Eliminar',
    props: ['deleteCat'],

    data:() => ({
        deleteSuccess: true,
        errorDeleteMedicamento: false,
        errorDeleteOperacion: false,
        errorDeleteDiagnostico: false,

        busy: false,
        id: '',
        type: '',
    }),

    methods: {
        async deleteData() {
            switch (this.type) {
                case 'operaciones':
                    this.busy = true
                    try {
                        const res = await fetch(`${url}/api/catalogos/operaciones/destroy/${this.id}`, {
                            method: 'delete',
                            headers: {
                                'Authorization': "Bearer "+ localStorage.getItem("token"),
                            }
                        })
                        if (!res.ok) {
                            this.busy = false
                            this.deleteSuccess = false
                            this.errorDeleteOperacion = true
                            const message = `An error has occured: ${res.status} - ${res.statusText}`;
                            throw new Error(message);
                        }
                        const data = await res.json()
                        if (data.status == true) {
                            this.busy = false
                            this.$emit('deleteSuccess', this.type)
                        }
                        this.id = ''
                        this.type = ''
                    }catch(error) {
                        console.log(error)
                        this.busy = false
                    }
                    break;
                
                case 'diagnósticos':
                    this.busy = true
                    try {
                        const res = await fetch(`${url}/api/catalogos/diagnosticos/destroy/${this.id}`, {
                            method: 'delete',
                            headers: {
                                'Authorization': "Bearer "+ localStorage.getItem("token"),
                            }
                        })
                        if (!res.ok) {
                            this.busy = false
                            this.deleteSuccess = false
                            this.errorDeleteDiagnostico = true
                            const message = `An error has occured: ${res.status} - ${res.statusText}`;
                            throw new Error(message);
                        }
                        const data= await res.json()
                        if (data.status == true) {
                            this.busy = false
                            this.$emit('deleteSuccess', this.type)
                        }
                        this.id = ''
                        this.type = ''
                    }catch(error) {
                        console.log(error)
                        this.busy = false
                    }
                    break;

                    case 'medicamentos':
                    this.busy = true
                    try {
                        const res = await fetch(`${url}/api/catalogos/medicamentos/${this.id}`, {
                            method: 'delete',
                            headers: {
                                'Authorization': "Bearer "+ localStorage.getItem("token"),
                            }
                        })
                        if (!res.ok) {
                            this.busy = false
                            this.deleteSuccess = false
                            this.errorDeleteMedicamento = true
                            const message = `An error has occured: ${res.status} - ${res.statusText}`;
                            throw new Error(message);
                        }
                        const data= await res.json()
                        if (data.status == true) {
                            this.busy = false
                            this.$emit('deleteSuccess', this.type)
                        }
                        this.id = ''
                        this.type = ''
                    }catch(error) {
                        console.log(error)
                        this.busy = false
                    }
                    break;
            
                default:
                    break;
            }
        },

        cerrarModal() {
            setTimeout(() => {
                this.deleteSuccess = true
                this.errorDeleteMedicamento = false
                this.errorDeleteDiagnostico = false
                this.errorDeleteOperacion = false
            }, 500);
            this.$emit('closeDeleteCat')
        },
        deleteDtaCat(id, type){
                this.id = id
                this.type = type
            }
    },

    mounted() {
        
    }
}
</script>

<style scoped>
    .fa-trash {
        color: #d15460;
    }
</style>