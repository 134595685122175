<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="9" lg="9">
                    <h3 class="txt_secondary text-uppercase mb-3">lista de {{ name }}</h3>
                </b-col>
                <b-col cols="12" sm="12" md="3" lg="3">
                    <div class="d-flex mb-4">
                        <span class="fa fa-save fa-lg my-auto px-3 icon_color"></span>
                        <button class="btn_formluario btn-block py-2 txt_white text-uppercase" @click="registar()">Registrar</button>
                    </div>
                </b-col>
            </b-row>
            <b-skeleton-wrapper class="p-0 m-0" :loading="loading">
                <template #loading>
                        <b-row align-h="center" class="g-3">
                        <b-col v-for="item in skeleton" :key="item.numero" class="mb-2" cols="12" sm="12" md="4" lg="4">
                            <b-skeleton class="size btn-block border-0" type="input"></b-skeleton>
                        </b-col>
                    </b-row>
                </template>
            </b-skeleton-wrapper>
            <b-row v-if="!loading">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <b-table-simple class="table-sm border_table">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style">Número</b-th>
                                <b-th class="th_style">Nombre</b-th>
                                <b-th class="th_style text-center">Acciones</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="catalogo in allData" :key="catalogo.id">
                                <b-td class="border_table"><span class="size-span">{{catalogo.numero}}</span></b-td>
                                <b-td class="border_table"><span class="size-span">{{catalogo.nombre}}</span></b-td>
                                <b-td class="border_table">
                                    <b-row align-h="center">
                                        <b-col cols="12" sm="12" md="3" lg="3">
                                            <div @click="deleteCat(catalogo.id)" class="btnAccion" title="Eliminar"><span class="fa fa-trash fa-lg my-auto icon_color"></span></div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="3" lg="3">
                                            <div @click="editCat(catalogo.id, catalogo.nombre)" class="btnAccion" title="Editar"><span class="fa fa-edit fa-lg my-auto icon_color"></span></div>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <div class="overflow-auto mt-4" v-if="catalogos.length > 10">
                <div>
                    <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="center"></b-pagination>
                </div>
            </div>
        </b-container>
        <b-button variant="btn_red" class="float-btn-back" :to="{ name: 'catalogos' }">
            <span class="fa fa-arrow-left my-float-btn"></span>
        </b-button>
        <Eliminar ref="eliminar" :deleteCat="openDelete" @closeDeleteCat="closeDeleteCat()" @deleteSuccess="deleteSuccess($event)"/>
        <Diagnostico ref="diagnostico" :modalDiagnostico="openDiagnostico" 
        @savedDiag="savedDiag()"
        @closeAddDiagnostico="closeAddDiagnostico()"/>
        <Operacion ref="operacion" :modalOperaciones="openOperaciones" @closeAddOperaciones="closeAddOperaciones()" @savedOperation="savedOperation()"/>
        <Medicamento ref="medicamento" @savedMedicamento="savedMedicamento()" :modalMedicamento="openMedicamento" @closeAddMedicamento="closeAddMedicamento()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <DeleteSuccess :abrir="openDeleteSuccess" @cerrarModalDeleteSuccess="cerrarModalDeleteSuccess()"/>

    </div>    
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import Eliminar from "@/components/Catalogos/Eliminar.vue"
import Diagnostico from '@/components/Catalogos/Registrar/Diagnostico.vue'
import Operacion from '@/components/Catalogos/Registrar/Operacion.vue'
import Success from "@/components/Modals/Success.vue"
import DeleteSuccess from '@/components/Modals/DeleteSuccess.vue'
import Medicamento from '@/components/Catalogos/Registrar/Medicamento.vue'

import { url } from "../../../settings"

export default {
    name: 'Registro',
    components: { Navbar, Eliminar, Diagnostico, Operacion, Success, DeleteSuccess, Medicamento },

    data: () => ({
        name: '',
        catalogos: [],
        openDelete: false,
        openSuccess: false,
        openDeleteSuccess: false,

        openOperaciones: false,
        openDiagnostico: false,
        openMedicamento: false,

        currentPage: 1,
        perPage:12,

        loading: false,
        skeleton: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27],
    }),

    computed: {
        allData () {
            const items = this.catalogos;
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },

        rows() {
            return this.catalogos.length
        },
    },

    methods: {
        loadingData() {
            if (this.catalogos.length == 0) {
                this.loading = true
            } else {
                this.loading = false
            }
        },

        getCatalogo() {
            switch (this.name) {
                case 'operaciones':
                    this.obtenerOperacionesPlaneadasRealizadas()
                    break;

                case 'diagnósticos':
                    this.obtenerDiagnosticosPreAndPost()
                    break;
                
                case 'medicamentos':
                    this.obtenerMedicamentos()
                    break;
            
                default:
                    break;
            }
        },

        registar() {
            switch (this.name) {
                case 'operaciones':
                    this.openOperaciones = true
                    break;
                
                case 'diagnósticos':
                    this.openDiagnostico = true
                    break;
                
                case 'medicamentos':
                    this.openMedicamento = true
                    break;
            
                default:
                    break;
            }
        },

        editCat(id, nombre) {
            switch (this.name) {
                case 'operaciones':
                    this.openOperaciones = true
                    this.$refs.operacion.editDtaCat(id,nombre,this.name)
                    break;
                
                case 'diagnósticos':
                    this.openDiagnostico = true
                    this.$refs.diagnostico.editDtaCat(id,nombre,this.name)
                    break;
                
                case 'medicamentos':
                    this.openMedicamento = true
                    this.$refs.medicamento.editDtaCat(id,nombre,this.name)
                    break;
            
                default:
                    break;
            }
        },

        deleteCat(id) {
            this.$refs.eliminar.deleteDtaCat(id,this.name)
            this.openDelete = true
        },

        async obtenerDiagnosticosPreAndPost() {
            try {
                const res = await fetch(`${url}/api/catalogos/diagnosticos`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                let j = 0
                for (const item of data.diagnosticos) {
                    j = j+1
                    var nombre ='numero'
                    var value = j
                    item[nombre] = value
                }

                this.catalogos = data.diagnosticos
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        },

        async obtenerOperacionesPlaneadasRealizadas() {
            try {
                const res = await fetch(`${url}/api/catalogos/operaciones`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                console.log({DataOP: data});
                let j = 0
                for (const item of data.operaciones) {
                    j = j+1
                    var nombre = 'numero'
                    var value = j
                    item[nombre] = value
                }
                this.catalogos = data.operaciones
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        },

        async obtenerMedicamentos() {
            try {
                const res = await fetch(`${url}/api/catalogos/medicamentos`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                let j = 0
                for (const item of data.medicamentos) {
                    j = j+1
                    var nombre = 'numero'
                    var value = j
                    item[nombre] = value
                }
                this.catalogos = data.medicamentos
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        },

        getName() {
            this.name = this.$route.params.id
            if (this.name === 'diagnosticos') {
                this.name = 'diagnósticos'
            }
        },
        cerrarModal(){
            this.openSuccess=false
        },
        /* catalogo de medicamentos */
        closeAddMedicamento(){
            this.openMedicamento = false
        },
        cerrarModalDeleteSuccess(){
            this.openDeleteSuccess = false;
        },
        savedMedicamento(){
            this.obtenerMedicamentos()
            this.openMedicamento = false
            this.openSuccess = true
        },
        closeAddOperaciones(){
            this.openOperaciones = false
        },
        savedOperation(){
            this.obtenerOperacionesPlaneadasRealizadas()
            this.openOperaciones = false
            this.openSuccess = true
        },
        closeAddDiagnostico(){
            this.openDiagnostico = false
        },

        savedDiag(){
            this.obtenerDiagnosticosPreAndPost()
            this.openDiagnostico = false
            this.openSuccess = true
        },
        closeDeleteCat(){
            this.openDelete = false
        },
        deleteSuccess(type) {
            this.openDelete = false
            switch (type) {
                case 'operaciones':
                    this.obtenerOperacionesPlaneadasRealizadas()
                    this.openDeleteSuccess = true
                    break;
                
                case 'diagnósticos':
                    this.obtenerDiagnosticosPreAndPost()
                    this.openDeleteSuccess = true
                    break;
                
                case 'medicamentos':
                    this.obtenerMedicamentos()
                    this.openDeleteSuccess = true
                    break;
            
                default:
                    break;
            }
        }
        
    },

    mounted() {
        this.getName()
        this.getCatalogo()
        this.loadingData()

    }
}
</script>

<style scoped>
    .btnAccion {
        cursor: pointer;
    }
</style>